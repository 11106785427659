// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._LgymsVe_672W68HhCXg{position:relative;padding:10px 15px;background-color:#010f02;border-radius:20px;display:flex;flex-direction:column;align-items:stretch}.fTs4GEbfC0tHaiFHJVn9{display:flex;justify-content:start}.JY6sKU9QtrCaBrH1odAW{margin-top:15px;flex:1;overflow:hidden}.JY6sKU9QtrCaBrH1odAW .NZFf355RzRM9yAOxA1AT{width:100%;height:100%}.gZfuMeVQshravxxHzM6Q{display:flex;justify-content:end}.ILpY61eMR8w7UFv92yla{position:absolute;top:20px;right:20px;display:flex;align-items:center;z-index:1001}.R1qkij1jcMIr_RHQV_y4{margin-left:10px;color:#00a32e;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/modules/package-ai-inspiration/components/scatter-chart/index.module.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,iBAAA,CACA,wBAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,mBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CAGF,sBACE,eAAA,CACA,MAAA,CACA,eAAA,CAEA,4CACE,UAAA,CACA,WAAA,CAIJ,sBACE,YAAA,CACA,mBAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,YAAA,CAGF,sBACE,gBAAA,CACA,aAAA,CACA,cAAA","sourcesContent":["// chart\n.box1 {\n  position: relative;\n  padding: 10px 15px;\n  background-color: #010f02;\n  border-radius: 20px;\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n}\n\n.box11 {\n  display: flex;\n  justify-content: start;\n}\n\n.box12 {\n  margin-top: 15px;\n  flex: 1;\n  overflow: hidden;\n\n  .chart {\n    width: 100%;\n    height: 100%;\n  }\n}\n\n.box13 {\n  display: flex;\n  justify-content: end;\n}\n\n.box14 {\n  position: absolute;\n  top: 20px;\n  right: 20px;\n  display: flex;\n  align-items: center;\n  z-index: 1001;\n}\n\n.zoomBtn {\n  margin-left: 10px;\n  color: #00a32e;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box1": `_LgymsVe_672W68HhCXg`,
	"box11": `fTs4GEbfC0tHaiFHJVn9`,
	"box12": `JY6sKU9QtrCaBrH1odAW`,
	"chart": `NZFf355RzRM9yAOxA1AT`,
	"box13": `gZfuMeVQshravxxHzM6Q`,
	"box14": `ILpY61eMR8w7UFv92yla`,
	"zoomBtn": `R1qkij1jcMIr_RHQV_y4`
};
export default ___CSS_LOADER_EXPORT___;
