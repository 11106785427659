import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getProduct } from '../../api/index.js';
import Table from '../../components/table/table.jsx';
import style from './topline.module.scss';
import Tutorial from './tutorial.jsx';
import Chart from '../scatter-chart/index.jsx';

const HEADER = [
  {
    title: 'Brand Logo Visibility',
    key: 'brand_logo_visibility',
    tips: 'This metric measures the probability of the brand logo being noticed in the first 3-5 seconds. It is based on 3M VAS algorithm.',
    value: 0,
    isSortDesc: true,
    isSortAsc: false,
  },
  {
    title: 'Variant Name Visibility',
    key: 'variant_name_visibility',
    tips: 'This metric evaluates the likelihood that the variant name will be noticed within the first 3-5 seconds of viewing. It utilizes the 3M VAS algorithm to provide this assessment.',
    value: 0,
    isSortDesc: false,
    isSortAsc: false,
  },
  {
    title: 'Considered Appeal',
    key: 'appeal',
    tips: 'This metric measures the percentage of respondents who found the packaging appealing within a sub-category shelf context. It is derived from consumer survey data.',
    value: 0,
    isSortDesc: false,
    isSortAsc: false,
  },
  {
    title: 'Intuitive Appeal',
    key: 'intuitive_appeal',
    tips: 'This metric measures the percentage of respondents who found the package design appealing in isolation. It is derived from an Implicit Reaction Test by consumers.',
    value: 0,
    isSortDesc: false,
    isSortAsc: false,
  },
  {
    title: 'Unpriced Purchase Intent',
    key: 'upi',
    tips: 'This metric measures the percentage of respondents who find the somewhat or very willing to purchase the product based on design alone. It is derived from consumer survey data.',
    value: 0,
    isSortDesc: false,
    isSortAsc: false,
  },
];

function Topline({ className, tab = 'table', filterData, subFilterData }) {
  // loading
  const [isLoading, setIsLoading] = useState(true);

  // sort
  const [sortKey, setSortKey] = useState('brand_logo_visibility');
  const [sort, setSort] = useState('desc');
  const changSort = (key) => {
    if (sortKey === key) {
      setSort(sort === 'desc' ? 'asc' : 'desc');
    } else {
      setSort('desc');
      setSortKey(key);
    }
  };

  // data
  const [originData, setOriginData] = useState([]);

  // avg
  const avgData = useMemo(() => {
    if (originData.length === 0) return {};

    let brand = 0;
    let name = 0;
    let appeal = 0;
    let intuitiveAppeal = 0;
    let intent = 0;
    for (const item of originData) {
      brand += item.brand_logo_visibility;
      name += item.variant_name_visibility;
      appeal += item.appeal;
      intent += item.upi;
      intuitiveAppeal += item.intuitive_appeal;
    }
    brand /= originData.length;
    name /= originData.length;
    appeal /= originData.length;
    intent /= originData.length;
    intuitiveAppeal /= originData.length;

    return {
      brand_logo_visibility: brand,
      variant_name_visibility: name,
      appeal: appeal,
      upi: intent,
      intuitive_appeal: intuitiveAppeal,
    };
  }, [originData]);

  // headerData
  const headerData = useMemo(() => {
    return HEADER.map((item) => {
      return {
        ...item,
        value: avgData[item.key] ?? 0.0,
        isSortDesc: item.key === sortKey && sort === 'desc',
        isSortAsc: item.key === sortKey && sort === 'asc',
      };
    });
  }, [avgData, sortKey, sort]);

  // fetch data
  const fetchData = useCallback(
    async (sort, order) => {
      setIsLoading(true);
      setOriginData([]);
      const { data } = await getProduct(
        filterData,
        subFilterData,
        undefined,
        sort,
        order
      );
      setOriginData(data);
      setIsLoading(false);
    },
    [filterData, subFilterData]
  );
  useEffect(() => {
    void fetchData();
  }, [filterData, subFilterData]);

  // sort
  const sortedData = useMemo(() => {
    return originData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return sort === 'asc' ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return sort === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [originData, sortKey, sort]);

  // body data
  const bodyData = useMemo(() => {
    return sortedData.map((item) => {
      return {
        id: item.sn,
        name: item.display_name,
        market: item.market,
        cover: item.cover,
        value: [
          item.brand_logo_visibility,
          item.variant_name_visibility,
          item.appeal,
          item.intuitive_appeal,
          item.upi,
        ],
      };
    });
  });

  if (tab === 'chart') {
    return (
      <div className={classNames(style.chartBox, className)}>
        <Chart className={style.chart} data={originData} />
      </div>
    );
  } else if (tab === 'table') {
    return (
      <div className={classNames(style.tblWrap, className)}>
        <Table
          className={style.table}
          header={headerData}
          data={bodyData}
          onAvgClick={changSort}
          loading={isLoading}
          originData={originData}
          small={false}
        />
        <div className={style.tutorialRow}>
          <Tutorial />
        </div>
      </div>
    );
  } else {
    return <div>Unknown tab {tab}</div>;
  }
}

export default Topline;
