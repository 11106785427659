import {
  gotflIcon,
  gotflIconGreen,
  trendsIcon,
  trendsIconGreen,
  marketMediaPerformanceIcon,
  marketMediaPerformanceIconGreen,
  mixCraterIcon,
  mixCraterIconGreen,
  knowledgeHubIcon,
  knowledgeHubIconGreen,
  businessCaseAssitantIcon,
  businessCaseAssitantIconGreen,
  conceptAiIcon,
  conceptAiIconGreen,
  chartIcon,
  chartIconGreen,
  aiTopDishWhite,
  aiTopDishGreen
} from '../icons/icons.jsx';

export const menuItems = [
  {
    id: 1,
    title: 'Get On The Front Line',
    url: '/frontlinetime',
    iconWhite: gotflIcon,
    iconGreen: gotflIconGreen,
    submenus: [
      {
        title: 'Consumer Theater',
        url: '/consumertheater',
        iconwhite: chartIcon,
        iconGreen: chartIconGreen,
      },
      {
        title: 'GOTFL Weena',
        url: '/gotflweena',
        iconwhite: chartIcon,
        iconGreen: chartIconGreen,
      },
      {
        title: 'Knorr Frontline',
        url: '/knorr',
        iconwhite: chartIcon,
        iconGreen: chartIconGreen,
      },
      {
        title: 'Frontline Time',
        url: '/frontlinetime',
        iconwhite: conceptAiIcon,
        iconGreen: conceptAiIconGreen,
      },
    ],
  },
  {
    id: 2,
    title: 'Trends',
    url: '/trends-dial',
    iconWhite: trendsIcon,
    iconGreen: trendsIconGreen,
    submenus: [
      {
        title: 'Trend Dial',
        url: '/trends-dial',
        iconwhite: conceptAiIcon,
        iconGreen: conceptAiIconGreen,
      },
    ],
  },
  {
    id: 3,
    title: 'Marketing & Media',
    url: '',
    iconWhite: marketMediaPerformanceIcon,
    iconGreen: marketMediaPerformanceIconGreen,
    submenus: [
      // {
      //   title: 'Market Performance \n (Coming soon)',
      //   url: '',
      //   iconwhite: conceptAiIcon,
      //   iconGreen: conceptAiIconGreen,
      // },
      {
        title: 'VISTA',
        url: 'https://app.powerbi.com/groups/me/apps/bc76a2fa-72b3-4584-bcd3-aeaf9af45ca4/reports/357f263d-7cfd-4006-bd32-18e465230856/ReportSection?experience=power-bi',
        iconwhite: chartIcon,
        iconGreen: chartIconGreen,
      },
      {
        title: 'BG Cell Performance Details',
        url: '/bu-feedback',
        iconwhite: chartIcon,
        iconGreen: chartIconGreen,
      },
      {
        title: 'Value Decomposition 2.0',
        url: 'https://app.powerbi.com/groups/me/apps/c3191c32-0f1d-4e01-9ec9-95f5b431cd11/reports/b8a86462-5c74-4aef-9f15-b08ea0b7e02e/ReportSection?ctid=6ac7a1f4-5fb1-4153-bb4f-12d2020a1f7d&experience=power-bi',
        iconwhite: chartIcon,
        iconGreen: chartIconGreen,
      },
      {
        title: 'Request Access to Value Decomposition 2.0',
        url: 'https://forms.office.com/r/7ZehmG96GU',
        iconwhite: chartIcon,
        iconGreen: chartIconGreen,
      },
      {
        title: 'Ratings & Review',
        url: '/ratings-review',
        iconwhite: conceptAiIcon,
        iconGreen: conceptAiIconGreen,
      },
    ],
  },
  {
    id: 4,
    title: 'Mix Crafter',
    url: '/conceptai',
    iconWhite: mixCraterIcon,
    iconGreen: mixCraterIconGreen,
    submenus: [
      {
        title: 'Concept GPT',
        url: 'https://designnode-azure.sba.cpp.unileversolutions.com/public-webapps/conceptgpt_nutrition/',
        iconwhite: conceptAiIcon,
        iconGreen: conceptAiIconGreen,
      },
      {
        title: 'Foods Innoflex GPT',
        url: 'https://bnlwe-da04-d-930339-webapi-01.azurewebsites.net/',
        iconwhite: conceptAiIcon,
        iconGreen: conceptAiIconGreen,
      },
      {
        title: 'Concept AI',
        url: '/conceptai',
        iconwhite: conceptAiIcon,
        iconGreen: conceptAiIconGreen,
      }
    ],
  },
  // {
  //   id: 5,
  //   title: 'Top Dish Trend Index',
  //   url: '/ai-top-dish',
  //   iconWhite: aiTopDishWhite,
  //   iconGreen: aiTopDishGreen,
  //   submenus: [
  //     {
  //       title: 'AI Top Dish Trend Index',
  //       url: '/ai-top-dish',
  //       iconwhite: chartIcon,
  //       iconGreen: chartIconGreen,
  //     },
  //   ],
  // },
  {
    id: 5,
    title: 'Knowledge Hub',
    url: '/commercializing-sustainability',
    iconWhite: knowledgeHubIcon,
    iconGreen: knowledgeHubIconGreen,
    submenus: [
      {
        title: 'Commercializing Sustainability',
        url: '/commercializing-sustainability',
        iconwhite: conceptAiIcon,
        iconGreen: conceptAiIconGreen,
      },
      {
        title: 'Plant Based',
        url: '/plantbased',
        iconwhite: chartIcon,
        iconGreen: chartIconGreen,
      },
      {
        title: 'Foods Tree of Wisdom',
        url: '/knowledge-tree',
        iconwhite: knowledgeHubIcon,
        iconGreen: knowledgeHubIconGreen,
      },
      {
        title: 'Pain points',
        url: '/painpoints',
        iconwhite: knowledgeHubIcon,
        iconGreen: knowledgeHubIconGreen,
      },
      {
        title: 'Menu Check Top Dish',
        url: '/top-dish',
        iconwhite: knowledgeHubIcon,
        iconGreen: knowledgeHubIconGreen,
      },
    ],
  },
  {
    id: 6,
    title: 'Business Case Assistant ',
    url: 'business-case-assistant',
    iconWhite: businessCaseAssitantIcon,
    iconGreen: businessCaseAssitantIconGreen,
    submenus: [
      {
        title: 'Business Case Assistant',
        url: '/business-case-assistant',
        iconwhite: chartIcon,
        iconGreen: chartIconGreen,
      },
    ],
  },
];
