import request from '../../../util/pythonApi.js';

const DATA_VERSION_QUERY = {
  v: 1,
};

export function getProduct(
  filter = {},
  subFilter = {},
  search,
  sort,
  sort_by,
  products
) {
  return request({
    url: '/v1/package-ai-performance/product',
    method: 'get',
    params: {
      filter: JSON.stringify(filter),
      sub_filter: JSON.stringify(subFilter),
      search,
      sort,
      sort_by,
      products: products?.join(','),
      ...DATA_VERSION_QUERY,
    },
  });
}

export function getFilter(query, filter = {}) {
  return request({
    url: '/v1/package-ai-performance/filter',
    method: 'get',
    params: {
      query,
      filter: JSON.stringify(filter),
      ...DATA_VERSION_QUERY,
    },
  });
}

export async function putFile(fileKey) {
  const res = await request({
    url: '/v1/azure_file',
    method: 'put',
    params: {
      file_key: fileKey,
    },
  });
  return res.data.url;
}

/**
 * @type {Map<string, string>}
 */
const imageCache = new Map();

export async function getImageById(id, market) {
  const file = `package-ai-inspiration/images/${market}/${id}.jpg`;
  if (imageCache.has(file)) {
    return imageCache.get(file);
  } else {
    const imgUrl = await putFile(file);
    imageCache.set(file, imgUrl);
    return imgUrl;
  }
}
