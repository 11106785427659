import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import classNames from 'classnames';
import * as echarts from 'echarts';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import style from './index.module.scss';
import buildTooltip from './tooltip.jsx';

const COLORS = ['#00a32e', '#a39300', '#d70728'];
const AXIS_OPTIONS = [
  { label: 'Brand Visibility', value: 'brand_logo_visibility' },
  { label: 'Variant Name Visibility', value: 'variant_name_visibility' },
  { label: 'Considered Appeal', value: 'appeal' },
  { label: 'Intuitive Appeal', value: 'intuitive_appeal' },
  { label: 'Unpriced Purchase Intent', value: 'upi' },
  { label: 'Taste', value: 'taste' },
  { label: 'High Quality', value: 'premium' },
  { label: 'Healthy', value: 'natural' },
  { label: 'Premium', value: 'value' },
  { label: 'Sustainable', value: 'sustainable' },
  { label: 'Modernity', value: 'modernity' },
  { label: 'Excitement', value: 'excitement' },
  { label: 'Desirability', value: 'desirability' },
];

function buildColor(rank) {
  if (rank < 0.3) {
    return COLORS[0];
  } else if (rank < 0.7) {
    return COLORS[1];
  } else {
    return COLORS[2];
  }
}

function findAxisLable(val) {
  const a = AXIS_OPTIONS.find((item) => item.value === val);
  if (!a) return 'Unknown';
  return a.label;
}

function buildValue(val) {
  const [i, d] = val.toFixed(1).split('.');
  if (d === '0') return i;
  else return `${i}.${d}`;
}

function buildSymbol(cover) {
  return `image://${cover}`;
}

function AxisSelect({ className, label, value, setValue }) {
  return (
    <div className={classNames(style.axisSelect, className)}>
      <div className={style.label}>{label}</div>
      <Select
        className={style.select}
        options={AXIS_OPTIONS}
        value={value}
        onChange={setValue}
      />
    </div>
  );
}

function Chart({ className, setRef, data, showValue }) {
  const chartBoxRef = useRef(null);
  useEffect(() => {
    setRef && setRef(chartBoxRef.current);
  }, [setRef, chartBoxRef.current]);

  const [xAxisKey, setXAxisKey] = useState('brand_logo_visibility');
  const [yAxisKey, setYAxisKey] = useState('variant_name_visibility');

  const chartRef = useRef(null);
  /**
   * @type {[echarts.ECharts|undefined, React.Dispatch<React.SetStateAction<echarts.ECharts>>]}
   */
  const [chart, setChart] = useState();
  const [zoom, setZoom] = useState(0);

  useEffect(() => {
    if (!chartRef.current) return;

    const options = {
      tooltip: {
        show: true,
      },
      xAxis: {
        axisLabel: {
          color: '#00A32E',
        },
        axisTick: {
          lineStyle: {
            color: '#00A32E',
          },
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            color: '#024A02',
            type: 'dashed',
          },
        },
        min: (val) => val.min + zoom,
        max: (val) => val.max - zoom,
      },
      yAxis: {
        axisLabel: {
          color: '#00A32E',
        },
        axisTick: {
          lineStyle: {
            color: '#00A32E',
          },
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          lineStyle: {
            color: '#024A02',
            type: 'dashed',
          },
        },
        min: (val) => val.min + zoom,
        max: (val) => val.max - zoom,
      },
      grid: {
        left: '0%',
        right: '2%',
        bottom: '0%',
        top: '2%',
        containLabel: true,
      },
      dataZoom: [
        {
          type: 'inside',
          orient: 'horizontal',
          show: false,
        },
        {
          type: 'inside',
          orient: 'vertical',
          show: false,
        },
      ],
      series: [
        {
          data: [],
          type: 'scatter',
          symbolSize: 40,
        },
      ],
    };

    const xKey = xAxisKey;
    // const xKey2 = `${xAxisKey}2`;
    // const xKey3 = `${xAxisKey}3`;
    const xKeyRank = `${xAxisKey}Rank`;
    const yKey = yAxisKey;
    // const yKey2 = `${yAxisKey}2`;
    // const yKey3 = `${yAxisKey}3`;
    const yKeyRank = `${yAxisKey}Rank`;

    for (const d of data) {
      // get data values
      const name = d.display_name;
      const cover = d.cover;
      const x = d[xKey];
      // const x2 = d[xKey2];
      // const x3 = d[xKey3];
      const xRank = d[xKeyRank];
      const y = d[yKey];
      // const y2 = d[yKey2];
      // const y3 = d[yKey3];
      const yRank = d[yKeyRank];

      // build data
      const dd = {
        value: [x, y],
        symbol: buildSymbol(cover),
        // itemStyle: {
        //   color: buildColor(xRank),
        // },
        tooltip: {
          backgroundColor: 'unset',
          borderColor: 'unset',
          borderWidth: 0,
          padding: 0,
          formatter: () => {
            return buildTooltip(
              cover,
              name,
              findAxisLable(xKey),
              buildValue(x),
              buildColor(xRank),
              findAxisLable(yKey),
              buildValue(y),
              buildColor(yRank)
            );
          },
        },
      };

      // add to series
      options.series[0].data.push(dd);
    }

    if (!chart) {
      const cc = echarts.init(chartRef.current);
      setChart(cc);
      cc.setOption(options);
    } else {
      chart.setOption(options);
    }
  }, [data, xAxisKey, yAxisKey, showValue, zoom]);

  // resize
  useEffect(() => {
    if (!chart) return;
    const handler = () => {
      chart.resize();
    };
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [chart]);

  // zoom
  const onZoomIn = useCallback(() => {
    setZoom((z) => {
      return z + 5;
    });
  }, [setZoom]);
  const onZoomOut = useCallback(() => {
    setZoom((z) => {
      return z - 5;
    });
  }, [setZoom]);

  return (
    <div ref={chartBoxRef} className={classNames(style.box1, className)}>
      <div className={style.box11}>
        <AxisSelect label="X-axis" value={xAxisKey} setValue={setXAxisKey} />
      </div>
      <div className={style.box12}>
        <div className={style.chart} ref={chartRef}></div>
      </div>
      <div className={style.box13}>
        <AxisSelect label="Y-axis" value={yAxisKey} setValue={setYAxisKey} />
      </div>
      <div className={style.box14}>
        <ZoomInOutlined className={style.zoomBtn} onClick={onZoomIn} />
        <ZoomOutOutlined className={style.zoomBtn} onClick={onZoomOut} />
      </div>
    </div>
  );
}

export default Chart;
