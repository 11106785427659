/* eslint-disable quotes */
const other = {
  'United States': {
    o1:
      'Source: NPD Top Dish Data, 2YE October 2023\n' +
      '<b>How to read: </b>\n' +
      'Occasion Share %: Eating Share concern the % of occasions that dish is consumed as a share of all eating occasions \n' +
      'Annual Eaiting per Capita: Average US consumer consumes Soup for lunch as main dish, 8.5 times a year. \n',
    o2:
      'NPD Group - Nationale Eating Trends (2YE October 2023)\n' +
      'Eating Share concern the % of occasions that dish is consumed as a share of all eating occasions \n' +
      '(How to read it - 24% of Bouillon eatings are soups)\n' +
      '<text style="color: #DA0000">Red italics indicate low sample, use directionally.</text>',
    o3:
      '<b>NPD Group - Nationale Eating Trends (2YE October 2023)</b>\n' +
      'Eating Share concern the % of occasions that dish is consumed as a share of all eating occasions',
    o4:
      'Dish Share refers to % of servings across total food service\n' +
      'Circana CREST Top 20 items in Foodservice Jan - Mar 2024',
  },
  Brazil: {
    mergeWidth1: { width: '40%' },
    o1:
      '<b>Description</b> - The below table covers the top dishes consumed in Brazil \n' +
      'Source -  Kantar Usage Panel for total foods & Bevarages. Period is Mat Q3 2023. \n' +
      '<b>How to Read the data</b>\n' +
      '<b>Participation on Total weekly  foods occasion</b> - Rice is 39.5% of all the dishes consumed ',
    o2:
      '<b>Kantar, Usage Foods & Beverages Panel - Total Seasoning (MAT Q1 2022)</b>\n' +
      'Food Occasions concern the % of occasions that dish is consumed as a share of all occasions\n' +
      'Note - Total Dish Cluster is regardless the use of seasonings ',
    o3:
      '<b>Kantar, Usage Foods & Beverages Panel - Mayonnaise in Dishes + Mayo Evolution ( MAT Q3 2023)</b>\n' +
      'Food Occasions concern the % of occasions that dish is consumed as a share of all occasions   \n' +
      '<b>How to Read the data 85.7% of Brazilians consume a sandwich at least once a week, and only 8,3% of the times a sandwich was consumed it was with mayonnaise.</b>',
    o4:
      '<b>Top Dish list</b> - qual operator connects, Epanel survey\n' +
      '<b>Dish penetration</b> - indicate estimates from GlobalData Menu Intelligence \n' +
      '<b>Category penetration</b> - indicative estimates from Euromonitor retail brand share ',
  },
  Argentina: {
    o1:
      '<b>Description</b> - The below table covers the top dish clusters in Argentina on a weekly basis. \n' +
      'Source -  Menu Argentino Desantanudos Nov 2020. \n' +
      '<b>How to Read the data</b>\n' +
      '<b>Occasion Share</b> - Salads is 20.6% of all the dish clusters consumed in the week ',
    o2:
      '<b>How to Read</b> \n' +
      'Occasion Share - Salads is 20.6% of all the dish clusters consumed in the week \n' +
      'Bouillon occasion Share % - 19.4% of the total bouillon occasions is Rice- polenta- Legumes- Grains',
    o3:
      '<b>Menu Argentino Desatanudos</b>\n' +
      '2020 data\n' +
      'Food Occasions concern the % of occasions that dish is consumed as a share of all occasions\n' +
      'Mayonnaise Occasions concern the % of times that this dish is prepared with mayonnaise',
  },
  Mexico: {
    mergeWidth1: { width: '35%' },
    o1:
      '<b>Description</b> - The below table covers the top dishes consumed in Mexico on a weekly basis. \n' +
      'Source -  Knatar Usage Panel for total foods. Period is Mat Q3 2022. \n' +
      '<b>How to Read the data</b>\n' +
      '<b>Occasion Share</b> - Eggs with Ham is 3.5% of all the dishes consumed in the week \n' +
      '<b>Dish Penetration</b> - 18.7% of all the households in MX consume Cereal w/ Milk in a week.\n' +
      '<b>Frequency</b> - Fried Beans is consumed 2 times per week. ',
    o2:
      '<b>Dish Penetration</b> = Households consuming the dish in the week / Total households in MX \n' +
      '<b>Category Penetration</b> = Households using the category/ ingredient in the dish/ Total number of households in MX \n' +
      '<b>Bouillons Headroom</b> = Total dish penetration % - bouillon penetration % \n' +
      '<b>Knorr Penetration %</b> = Occasion % of Knorr in a dish * Total Bouillon penetration in the dish\n' +
      'Note - These dishes are mapped to desired meal time (Breakfast, or lunch) ',
    o3:
      '<b>Top Dishes MEX (Norte A1 A2) - Mayonnaise in Dishes (2024)</b>\n' +
      'Food Occasions Share concern the % of occasions that dish is consumed as a share of all occasions                                                                         \n' +
      '  How to Read the data                    \n' +
      'Occasion Share -  sandwiches represent 4,7 %of all the dishes consumed,  35% of Mayonnaise occasions come from the use in Sandwiches         \n' +
      'Dish Penetration - 22% of all the households in north of MX consume sandwiches in a day, 59% of the sandwiches include mayo.',
    o4:
      '<b>Top Dish list</b> - qual operator connects, Epanel survey\n' +
      '<b>Dish penetration</b> - indicate estimates from GlobalData Menu Intelligence \n' +
      '<b>Category penetration</b> - indicative estimates from Euromonitor retail brand share ',
    o3la:
      '*we only measure the time fin  penetration, first column is % of people in yesterday, second column is % of total dishes/meal occasions, third is % of dishes that used mayonnaise, fourth is % that dish contributes to mayonnaise use/occasions\n' +
      'the time reference is used only when talking about penetration on a person basis . because if the period is longer or shorter the penetration on person basis varies, in the other variables it does not refer to any particular period',
  },
  'United Kingdom': {
    mergeWidth1: { width: '20%' },
    o1:
      '<b>Description</b> - Top dish Mothership data 2023 P2. \n' +
      'Source -  Kantar Usage Panel for total foods. Period is Mat Q3 2022. \n' +
      '<b>How to Read the data</b>\n' +
      '<b>Occasion Share %</b> - Food Occasions concern the % of occasions that dish is consumed as a share of all occasions',
    o2:
      '<b>How to Read the data</b>\n' +
      '<b>Occasion Share</b> - Food Occasions concern the % of occasions that dish is consumed as a share of all occasions\n' +
      '<b>Category Occasion Share</b> - 16.8 % of the total bouillon/stock occasions is Italian Dishes\n' +
      '<b>Brand occasion Share</b> - 16.2% of total Knorr stock/ bouillon is used to prepare Italian Dishes. ',
    o3:
      '<b>UK Mothership, P2 2023 (52 w/e 19 Feb 23)</b>\n' +
      '<b>Occasion Share %</b> - Food Occasions concern the % of occasions that dish is consumed as a share of all occasions\n' +
      '<b>Category Occasion Share</b> - 45.1 % of the total mayonnaise occasions is Sandwiches',
    o4:
      '<b>Dish list and dish penetration</b> is from Lumina Eating Out Consumer panel report.\n' +
      '<b>Dish share data</b> is penetration of dish as % of all eating out occassions.\n' +
      '<b>Fries/chips</b> is not reflected in the TOP dish list as it is considered a \'side\' dish but has high relevance across OGs (chips are consumed in 29% of eating out occasions ) --> significant for Mayo penetration.',
  },
  Belgium: {
    o1:
      '<b>Description</b> - The below table covers the top (main) dishes consumed in Belgium on a weekly basis for dinner.\n' +
      'Source -  U&A Toluna 2023. \n' +
      '<b>How to Read the data</b>\n' +
      '<b>Occasion Share</b> - Pizza is 4.8% of all the (main) dishes consumed/ prepared during dinner in Belgium',
    o2:
      '<b>U&A Toluna 2023</b> \n' +
      '<b>How to read</b> \n' +
      'Occasion Share - Soup is 4% of all the dishes consumed in the week \n' +
      'Bouillons consumed 37% in soups (soups, velvety, etc.) within the "homemade" main course',
  },
  Germany: {
    o1:
      '<b>Source:</b> Nutrition Occasions Study 2023 (Universe: what people eat and cook)\n' +
      '<b>How to Read:</b>\n' +
      '<b>% Occasions:</b> - Salads is consumed in 1.4% of occasions.\n',
  },
  France: {
    o1:
      '<b>Description</b> - The below table covers the top (main) dishes consumed in France on a weekly basis. \n' +
      'Source -  Kantar Usage Panel MAT P13’22. \n' +
      '<b>How to Read the data</b>\n' +
      '<b>Occasion Share</b> - Pizza is 4.8% of all the (main) dishes consumed/ prepared ',
    o2:
      '<b>Kantar Usage Panel MAT P9\'22</b> \n' +
      '<b>How to read </b>\n' +
      'Occasion Share - Soup is 4% of all the dishes consumed in the week \n' +
      'Bouillons consumed 37% in soups (soups, velvety, etc.) within the "homemade" main course',
    o3:
      '<b>Kantar Worldpanel Food Usage Data - Top Dishes</b>\n' +
      'MAT P6 2021 for Total Food Occasions\n' +
      'MAT P11 2021 for Mayo Occasions\n' +
      'Food Occasions concern the % of occasions that dish is consumed as a share of all occasions',
  },
  Philippines: {
    mergeWidth1: { width: '30%' },
    o1:
      'Source -  Menu Check Oct 2021. \n' +
      '<b>How to Read the data</b>\n' +
      '<b>Dish Share %</b> - Rice is 29.8% of all the dishes consumed/ served in the week \n' +
      '<b>Dish Penetration %</b> - 76% of all the households in PH consume Fried Fish in a week.',
    o2:
      '<b>How to Read the data</b>\n' +
      '<b>Dish Share</b> % - Tinolang Manok is 0.7% of all the dishes consumed/ served in the week \n' +
      '<b>Dish Penetration</b> % - 25% of all households in PH consume Tinolang Manok in a week.\n' +
      '<b>Brand / competitor penetration in dish</b> % -   Of all the Tinolang Manok dishes prepared, 73% use Knorr Cubes to prepare it\n' +
      'Note - Knorr is the bouillon category in PH',
    o3:
      '<b>Dish Share (%)</b> shows the number of occasions that dish is eaten, as a share of all occasions\n' +
      '<b>Mayonnaise Penetration (%)</b> indicates the % penetration of mayonnaise in the given dish',
    o4:
      '<b>Top dish list</b> sourced from Streetbees Q3, 2022 report\n' +
      '<b>Dish share</b> - % share of dining occasion derived from Streetbees report.\n' +
      '<b>Bouillon & Seasoning Penetration</b> – information available only for PH at Total market level. Derived from \'Armstrong\' UFS sales tool, hence data based on Visited Customers only\n' +
      '<b>Mayo Penetration</b> – information available only for PH at Total market level. Derived from \'Armstrong\' UFS sales tool, hence data based on Visited Customers only',
  },
  Vietnam: {
    mergeWidth2: { width: '42%' },
    mergeWidth3: { width: '32%' },
    o1:
      'The below table covers the list of top prepared dishes in Vietnam. Source -  Menu Check 2021. \n' +
      '<b>Dish Share %</b> - Soupy is 24.58% of all the dishes consumed/ served in the week',
    o2: '<b>Category Pen %</b> into dish cluster (Bouillon or seasoning pen)​ -Pen of the ingredient in the occasions of preparation / consumption. 81% of all soup dishes make use of bouillon as an seasoning \n' +
      '<b>Brand share %</b> - When bouillon is used in preparing Soup dishes, Knorr bouillon is use 59% of the time ',
    o3: '<b>Brand share %</b> - When bouillon is used in preparing of Soup dishes, Knorr bouillon is use 59% of the time.',
  },
  Thailand: {
    mergeWidth1: { width: '12%' },
    o1:
      '<b>Description</b> - The below table covers the list of top prepared dishes in Thailand. Source -  Menu Check 2022. \n' +
      '<b>How to Read the data</b>\n' +
      '<b>Dish Share %</b> - Stir-Fried Basil with Pork is 2.8% of all the dishes consumed/ served in the week \n' +
      '<b>Dish Penetration %</b> - 38.5% of all the households in TH consume Stir-Fried Basil with Pork in a week.',
    o2:
      '<b>Dish Share %</b> - Stir-Fried Basil with Pork is 2.8% of all the dishes consumed/ served in the week \n' +
      '<b>Dish Penetration %</b> - 38.5% of all the households in TH consume Stir-Fried Basil with Pork in a week.\n' +
      '<b>Category Share % (Bouillon or seasoning)</b> - Share of the ingredient in the occasions of preparation / consumption. 64% of all boiled noodle soup with pork makes use of bouillon as an ingredient\n' +
      '<b>Brand share %</b> - When bouillon is used in preparing of boiled noodle soup with pork, Knorr bouillon is use 78% of the time. \n' +
      '<text style="color: #DA0000">Values marked in red have a low base.</text>',
    o4:
      '<b>Top dish list</b> sourced from Streetbees Q3, 2022 report\n' +
      '<b>Dish share</b> - % share of dining occasion derived from Streetbees report.',
  },
  Indonesia: {
    mergeWidth1: { width: '20%' },
    o1:
      '<b>Description</b> - The below table covers the top dishes consumed in Indonesia.  \n' +
      'Source -  Menu Check May 2023. \n' +
      '<b>How to Read the data</b>\n' +
      '<b>Dish Share %</b> - Ayam Goreng is 6% of all the dishes consumed/ served in the week \n' +
      '<b>Dish Penetration %</b> - 36% of all the households in ID consume Ayam Goreng in a week.',
    o2:
      '<b>How to Read the data</b>\n' +
      '<b>Dish Share %</b> - Ayam Goreng is 6% of all the dishes consumed/ served in the week \n' +
      '<b>Dish Penetration %</b> - 36% of all the households in ID consume Ayam Goreng in a week.\n' +
      '<b>Brand/ Product Usage %</b> -   Royco is used in 18% of all the Ayam Goreng Dishes prepared in the week. \n' +
      '<b>Brand Penetration %</b> - (Brand Usage% * Dish Penetration): 6% of total HH in ID make use of Royco in the preparation on Ayam Goreng ',
    o4:
      '<b>Top dish list</b> sourced from Streetbees Q3, 2022 report\n' +
      '<b>Dish share</b> - % share of dining occasion derived from Streetbees report.',
    o1la: 'Note - ID is in the process of identifying the top dishes to be prioritized for bouillons. ',
  },
  'South Africa': {
    o1: 'Source - Kantar 2023\n' + 'DIsh penetration',
  },
  Colombia: {
    o1:
      '<b>Description</b> - The below table covers the top dishes consumed in Colombia\n' +
      'Source -  Kantar Usage Panel Año móvil - septiembre  2023. \n' +
      '<b>How to Read the data</b>\n' +
      '<b>Occasion Share</b> - Rice is 18,7% of all the dishes consumed ',
    o3:
      '<b>Kantar,  Usage Panel Año móvil - septiembre  2023 -  Mayonnaise in Dishes Description -  The below table covers the top dishes consumed in Colombia</b>\n' +
      'How to Read the data\n' +
      'Occasion Share - 3,3% of Colombians consumed some type of sandwiches at least once a week, and 8,9% of the times a sandwiches was consumed  with ketchup. Penetration - 3,8% of Colombians consume  sandwiches with ketchup once week"\t\t\t\n',
  },
  Finland: {
    o1:
      'Description - The below table covers the top (main) dishes consumed in Finland on a weekly basis for dinner.\n' +
      'Source -  U&A Toluna 2023. \n' +
      'How to Read the data\n' +
      'Occasion Share - Pizza is 4.8% of all the (main) dishes consumed/ prepared during dinner in Finland',
    o2:
      '<b>U&A Toluna 2023</b> \n' +
      '<b> How to read </b>\n' +
      'Occasion Share - Soup is 4% of all the dishes consumed in the week \n' +
      'Bouillons consumed 37% in soups (soups, velvety, etc.) within the "homemade" main course',
    o3: 'mini-U&A Toluna 2024 (focus on mayo consumers)',
  },
  Spain: {
    o1:
      '<b>Description</b> - The below table covers the top dish clusters in Spain. \n' +
      'Data - Ranking dishes consumed at home | MAT Q3 2020  \n' +
      '<b>How to Read the data</b>\n' +
      '<b>Occasion Share</b> - Meat dishes is 30.5% of all the dish clusters consumed',
    o2:
      'Data - Top recipes for bouillon cube Total Spain MAT Q3 2020\n' +
      '<b>How to Read</b> \n' +
      ' Bouillon occasion Share % - 15.6% of the total bouillon occasions is Lentils',
    o3:
      '<b>Mayo Ocassions</b>\n' +
      '2022 data\n' +
      'Food Occasions concern the % of occasions that dish is consumed as a share of all occasions\n' +
      'Mayonnaise Occasions concern the % of times that this dish is prepared with mayonnaise',
    o2la: 'Note - Spain is in the process of identifying the top dishes to be prioritized for bouillons. ',
  },
  Sweden: {
    o1:
      'Description - The below table covers the top (main) dishes consumed in Sweden on a weekly basis for dinner.\n' +
      'Source -  U&A Toluna 2023. ',
    o2: '<b>U&A Toluna 2023</b>',
    o3: 'mini-U&A Toluna 2024 (focus on mayo consumers)',
  },
  Australia: {
    mergeWidth1: { width: '20%' },
    mergeWidth2: { width: '30%' },
    o1:
      'Source - Menu Check (lite) 2023\n' +
      '<b>How to Read the data</b>\n' +
      '<b>Dish Cluster Share %</b> - The share of a dish cluster based on all the dishes consumed in-home. E.g. 18% of all dishes consumed at home is coming from Bread Cluster',
    o2:
      '<b>How to Read the data</b>\n' +
      'Source: Menu Check (lite) 2023\n' +
      '<b>Dish Penetration %</b> - the percentage of all households that prepared/cooked the dish in P4W using Stock. E.g. 14% of all HH used Stock as an ingredient to prepare Beef Stew/Cassrole.',
    o3:
      '<b>How to Read the data</b>\n' +
      'Source: Menu Check (lite) 2023\n' +
      '<b>Dish Penetration %</b> - the percentage of all households that prepared/cooked the dish in P4W using Mayo. E.g. 33% of all HH used Mayo as an ingredient to prepare Green Salad. ',
  },
  'Ethopia': {
    o1: 
      'Dish Penetration - Source: Kantar 2023\n' +
      'Weekday / Weekend - Source: Nielsen 2022',
  },
  'Kenya': {
    o1:
      'Source - Kantar 2023\n' +
      'DIsh penetration',
  },
  'Nigeria': {
    o1:
      'Dish Penetration - Source: Kantar 2023\n' +
      'Weekday / Weekend - Source: Nielsen 2022',
  },
  'Egypt': {
    o1:
      'Source: Panel Diary Menucheck (KWP) 2021',
    o2:
      'Source: Panel Diary Menucheck (KWP) 2021',
  },
  'Morocco': {
    o1:
      'Source: Panel Diary Menucheck (KWP) 2022',
    o2:
      'Source: Panel Diary Menucheck (KWP) 2022',
  },
  'Netherlands': {
    o1:
      'The below table covers the top main dishes consumed in the Netherlands in total as well as for Lunch and Dinner. It includes the top dishes within the segment (for example, which sandwiches or which soups are mostly consumed) in general as well as for breakfast, lunch and dinner.\n' +
      '\n' +
      'How to read the data:\n' +
      '<b>Total dish penetration: </b>percentage of people in The Netherlands that prepare the dish, regardless of the meal (breakfast, snack, lunch or dinner) (e.g., 23% of dutch consumers have sandwich (any kind of sandwich); 12% have sandwich with cheese).\n' +
      '<b>Breakfast size: </b>percentage of consumers in The Netherlands that prepare the dish for breakfast (e.g., 21% of people in the Netherlands have Sandwich with cheese for Breakfast)\n' +
      '\n' +
      "AVG (aardappel, vlees & groenten) is the combination of those who selected potato dishes, vegetable and meat dishes together. It's within the top dishes for dinner in NL --> 17% of people in the Netherlands consume it for dinner\n" +
      'The top breakfast dish in the Netherlands is Sandwich - from which the most popular one is sandwich with cheese\n' +
      '\n' +
      '<b>Source -  U&A 2023 (Metrix Lab) n=3,135</b>',
    o2:
      'Description - The below table covers the top (main) dishes consumed in the Netherlands for total occasions.\n' +
      'Source -  U&A 2023 (Metrix Lab) n=3,135'
      
  },
  'Italy': {
    o1: 
      '<b>Source:  Metrixlab Occasions Study 2023</b>\n' +
      '<b>Dish Penetration %:</b> 7.2% of all the dishes that were made yesterday were Pasta Pomodoro',
    o2:
      '<b>Source:  Metrixlab Occasions Study 2023</b>\n' +
      '<b>Food Occasions </b>concern the % of occasions that dish is consumed as a share of all occasions\n' +
      '<b>Bouillon Occasions : </b>% split of bouillon occasions. Of all dishes done with bouillon, 30% is a soup',
    o3:
      '<b>Source:  Metrixlab Ocassions Study 2023.</b>\n' +
      '<b>Food Occasions </b>concern the % of occasions that dish is consumed as a share of all occasions\n' +
      '<b>Mayonnaise Occasions : </b>% split of mayo occasions. Of all dishes done with mayonnaise, 30% is a sandwich',
  },
  'Poland': {
    o1:
      '<b>Top Dish Study 2023, Cooked from Scratch only</b>\n' +
      '<b>Dish Penetration %</b> - shows the % of the households consuming the specific dish <b>on a monthly basis</b>',
    o2:
      '<b>Top Dish Study 2023, Cooked from Scratch only</b>\n' +
      '<b>Dish Penetration %</b> - shows the % of the households consuming the specific dish on a monthly basis\n' +
      '<b>Bouillon Penetration % in dish</b> - 45% of soups occasions used bouillon',
    o3:
      '<b>Top Dish Study 2023, Cooked from Scratch only</b>\n' +
      '<b>Dish Penetration %</b> - shows the % of the households consuming the specific dish on a monthly basis\n' +
      '<b>Mayo Penetration % in dish</b> - 28% of spreads/dips occasions involved mayo usage',
  },
};

const allTitle = {
  'United States': {
    dataA: 'Top Dishes in the United States',
    dataB: 'Bouillon Top Host Dishes in the United States',
    dataC: 'Mayonnaise Top Host Dishes in the United States',
    dataD: 'OOH top dishes in the United States',
  },
  'United Kingdom': {
    dataA: 'Top Dishes in the United Kingdom',
    dataB: 'Bouillon Top dishes in the United Kingdom',
    dataC: 'Mayonnaise Top dishes in the United Kingdom',
    dataD: 'OOH top dishes in the United Kingdom',
  },
  Philippines: {
    dataA: 'Top Dishes in Philippines',
    dataB: 'Bouillon Top Dishes in the Philippines',
    dataC: 'Mayonnaise Top dishes in the Philippines',
    dataD: 'OOH top dishes in the Philippines',
  },
  Indonesia: {
    dataA: 'Top Dishes in Indonesia',
    dataB: 'Bouillon Top Dishes in Indonesia',
    dataC: '',
    dataD: 'OOH top dishes in Indonesia',
  },
  Thailand: {
    dataA: 'Top Dishes in Thailand',
    dataB: 'Bouillon & Seasoning Top Dishes in Thailand',
    dataC: '',
    dataD: 'OOH top dishes in Thailand',
  },
  Vietnam: {
    dataA: 'Top Dishes in Vietnam',
    dataB: 'Bouillon & Seasoning Top Dishes in Vietnam',
    dataC: 'BRAND SHARE Top Dishes in Vietnam',
    dataD: '',
  },
  Mexico: {
    dataA: 'Top Dishes in Mexico',
    dataB: 'Bouillon Top dishes in MX',
    dataC: 'Mayonnaise Top dishes in NORTH MX',
    dataD: 'OOH top dishes in MX',
  },
  Brazil: {
    dataA: 'Top Dishes in Brazil',
    dataB: 'Bouillon Top dishes in Brazil',
    dataC: 'Mayonnaise Top dishes in Brazil',
    dataD: 'OOH top dishes in BR',
  },
  Argentina: {
    dataA: 'Top Dish Cluster in Argentina',
    dataB: 'Bouillon Top dish Clusters in Argentina',
    dataC: 'Mayonnaise Top dishes in Argentina',
    dataD: '',
  },
  Colombia: {
    dataA: 'Top Dishes in Colombia',
    dataB: '',
    dataC: 'Ketchup Top dishes in Colombia',
    dataD: '',
  },
  Australia: {
    dataA: 'Top Dish Clusters in Australia',
    dataB: 'Bouillon Top Dishes in Australia',
    dataC: 'Mayonnaise Top dishes in Australia',
    dataD: '',
  },
  Sweden: {
    dataA: 'Top 10 Main Dishes in Sweden',
    dataB: 'Bouillon Top dishes in Sweden',
    dataC: 'Mayo Top dishes in Sweden',
    dataD: '',
  },
  France: {
    dataA: 'Top 10 Main Dishes in France',
    dataB: 'Bouillon Top dishes in France',
    dataC: 'Mayonnaise Top dishes in France',
    dataD: '',
  },
  Belgium: {
    dataA: 'Top 10 Main Dishes in Belgium',
    dataB: 'Bouillon Top dishes in Belgium',
    dataC: '',
    dataD: '',
  },
  Germany: {
    dataA: 'Top Dishes in Germany',
    dataB: 'Bouillon Top dishes in Germany',
    dataC: 'Mayonnaise Top dishes in Germany',
    dataD: '',
  },
  Spain: {
    dataA: 'Top Dish Cluster in Spain',
    dataB: 'Bouillon Top dish in Spain',
    dataC: 'Mayonnaise Top dishes in Spain',
    dataD: '',
  },
  Finland: {
    dataA: 'Top 10 Main Dishes in Finland',
    dataB: 'Bouillon Top dishes in Finland',
    dataC: 'Mayo Top dishes in Finland',
    dataD: '',
  },
  'South Africa': {
    dataA: 'Top Dish Cluster in South Africa ',
    dataB: '',
    dataC: '',
    dataD: '',
  },
  'Ethopia': {
    dataA: 'Top Dish Cluster in Ethopia',
    dataB: '',
    dataC: '',
    dataD: '',
  },
  'Kenya': {
    dataA: 'Top Dish Cluster in Kenya',
    dataB: '',
    dataC: '',
    dataD: '',
  },
  'Nigeria': {
    dataA: 'Top Dish Cluster in Nigeria',
    dataB: '',
    dataC: '',
    dataD: '',
  },
  'Egypt': {
    dataA: 'Top Dishes in Egypt',
    dataB: 'Bouillon Top dishes in Egypt',
    dataC: '',
    dataD: '',
  },
  'Morocco': {
    dataA: 'Top Dishes in Morocco',
    dataB: 'Bouillon Top dishes in Morocco',
    dataC: '',
    dataD: '',
  },
  'Netherlands': {
    dataA: 'Top 10 Main Dishes in the Netherlands',
    dataB: 'Top 10 Main Dishes in the Netherlands for Meal Solutions',
    dataC: '',
    dataD: '',
  },
  'Italy': {
    dataA: 'Top Dishes in Italy',
    dataB: 'Bouillon Top dishes in Italy',
    dataC: 'Mayonnaise Top dishes in Italy',
    dataD: '',
  },
  'Poland': {
    dataA: 'Top 10 Main Dishes in Poland',
    dataB: 'Bouillon Top dishes in Poland',
    dataC: 'Mayo Top dishes in Poland',
    dataD: '',
  },
};

const allStyleCountry = {
  'United States': {
    dataA: ['w1', 'topDish3', 'w3', 'w4'],
    dataB: ['topDish3', 'w4', 'w4'],
    dataC: ['topDish3', 'w4', 'w4'],
    dataD: ['w2', 'topDish4', 'w4'],
  },
  'United Kingdom': {
    dataA: ['w3', 'topDish5', 'w3'],
    dataB: ['topDish1', 'w3', 'w4', 'w1', 'w1'],
    dataC: ['topDish4', 'w3', 'w5'],
    dataD: ['w1', 'topDish0', 'w2', 'w6', 'w3'],
  },
  Philippines: {
    dataA: ['w3', 'topDish1', 'w3', 'w3', 'w3'],
    dataB: ['topDish15', 'w2', 'w2', 'w3', 'w4', 'w3', 'w3'],
    dataC: ['topDish1', 'w6', 'w6'],
    dataD: ['w2', 'w6', 'w2', 'w6', 'w6'],
  },
  Indonesia: {
    dataA: ['w1', 'topDish0', 'w3', 'w3', 'w3'],
    dataB: ['topDish8', 'w3', 'w1', 'w1', 'w17', 'w0', 'w2', 'w0', 'w2'],
    dataC: ['w1'],
    dataD: ['w4', 'topDish3', 'w4'],
  },
  Thailand: {
    dataA: ['w1', 'topDish3', 'w3', 'w3'],
    dataB: [
      'topDish15',
      'w2',
      'w3',
      'w2',
      'w3',
      'w06',
      'w06',
      'w3',
      'w06',
      'w06',
    ],
    dataC: ['w1'],
    dataD: ['w3', 'topDish3', 'w6'],
  },
  Vietnam: {
    dataA: ['topDish3', 'w6'],
    dataB: ['w3', 'w1', 'w4', 'w1', 'w2', 'w0', 'w0', 'w0', 'w0'],
    dataC: ['w1',],
    dataD: ['w1'],
  },
  Mexico: {
    dataA: ['w1', 'topDish30', 'w6', 'w6', 'w3'],
    dataB: ['topDish10', 'w2', 'w2', 'w3', 'w4', 'w3', 'w4'],
    dataC: ['topDish20', 'w4', 'w4', 'w4', 'w4'],
    dataD: ['w3', 'topDish20', 'w4', 'w6', 'w4'],
  },
  Brazil: {
    dataA: ['topDish1', 'w9'],
    dataB: ['topDish1', 'w3', 'w4', 'w4'],
    dataC: ['topDish20', 'w4', 'w4', 'w4', 'w4'],
    dataD: ['w1', 'topDish20', 'w4', 'w7', 'w4'],
  },
  Argentina: {
    dataA: ['w3', 'topDish3', 'w3'],
    dataB: ['topDish1', 'w3', 'w4', 'w4'],
    dataC: ['topDish25', 'w4', 'w4', 'w9'],
    dataD: ['w1'],
  },
  Colombia: {
    dataA: ['topDish1', 'w6', 'w6'],
    dataB: ['w1'],
    dataC: ['topDish30', 'w6', 'w6', 'w6'],
    dataD: ['w1'],
  },
  Australia: {
    dataA: ['w0', 'topDish30', 'w3', 'w1', 'w1', 'w1', 'w1', 'w1'],
    dataB: ['w1', 'topDish1', 'w3', 'w2', 'w2'],
    dataC: ['w1', 'topDish1', 'w3', 'w2', 'w2'],
    dataD: ['w1'],
  },
  Sweden: {
    dataA: ['w1', 'topDish3', 'w7'],
    dataB: ['topDish1', 'w6', 'w6'],
    dataC: ['topDish3', 'w9'],
    dataD: ['w1'],
  },
  France: {
    dataA: ['w1', 'topDish3', 'w7'],
    dataB: ['topDish0', 'w7', 'w6'],
    dataC: ['topDish40', 'w7', 'w7'],
    dataD: ['w1'],
  },
  Belgium: {
    dataA: ['w1', 'topDish3', 'w7'],
    dataB: ['topDish1', 'w6', 'w6'],
    dataC: ['w1'],
    dataD: ['w1'],
  },
  Germany: {
    dataA: ['w1', 'topDish3', 'w7'],
    dataB: ['topDish40', 'w7', 'w7'],
    dataC: ['topDish40', 'w7', 'w7'],
    dataD: ['w1'],
  },
  Spain: {
    dataA: ['w1', 'topDish3', 'w7'],
    dataB: ['w1', 'topDish3', 'w7'],
    dataC: ['topDish1', 'w6', 'w6'],
    dataD: ['w1'],
  },
  Finland: {
    dataA: ['w1', 'topDish3', 'w7'],
    dataB: ['topDish1', 'w6', 'w6'],
    dataC: ['topDish3', 'w9'],
    dataD: ['w1'],
  },
  'South Africa': {
    dataA: ['w1', 'topDish0', 'w3', 'w3', 'w3'],
    dataB: ['w1'],
    dataC: ['w1'],
    dataD: ['w1'],
  },
  'Ethopia': {
    dataA: ['w1', 'topDish0', 'w3', 'w3', 'w3'],
    dataB: ['w1'],
    dataC: ['w1'],
    dataD: ['w1'],
  },
  'Kenya': {
    dataA: ['w1', 'topDish0', 'w7'],
    dataB: ['w1'],
    dataC: ['w1'],
    dataD: ['w1'],
  },
  'Nigeria': {
    dataA: ['w1', 'topDish0', 'w3', 'w3', 'w3'],
    dataB: ['w1'],
    dataC: ['w1'],
    dataD: ['w1'],
  },
  'Egypt': {
    dataA: ['w1', 'topDish3', 'w3', 'w3'],
    dataB: ['w1', 'topDish3', 'w3', 'w3'],
    dataC: ['w1'],
    dataD: ['w1'],
  },
  'Morocco': {
    dataA: ['w1', 'topDish3', 'w3', 'w3'],
    dataB: ['w1', 'topDish3', 'w3', 'w3'],
    dataC: ['w1'],
    dataD: ['w1'],
  },
  'Netherlands': {
    dataA: ['w1', 'topDish30', 'w5', 'w3', 'w3','w3','w3'],
    dataB: ['w1', 'topDish0', 'w7'],
    dataC: ['w1'],
    dataD: ['w1'],
  },
  'Italy': {
    dataA: ['w1', 'topDish0', 'w7'],
    dataB: ['topDish1', 'w6', 'w6'],
    dataC: ['topDish3', 'w4', 'w4'],
    dataD:  ['w1'],
  },
  'Poland': {
    dataA: ['w1', 'topDish0', 'w7'],
    dataB: ['topDish1', 'w6', 'w6'],
    dataC: ['topDish3', 'w4', 'w4'],
    dataD: ['w1'],
  },
};

const styleData = {
  writeTitle: {
    width: '70%',
    padding: '20px 10px 20px 50px',
    fontSize: '11px',
    color: '#00a32e',
  },
  topDish8: { width: '8%', justifyContent: 'start', paddingLeft: '10px' },
  topDish10: { width: '10%', justifyContent: 'start', paddingLeft: '10px' },
  topDish15: { width: '15%', justifyContent: 'start', paddingLeft: '10px' },
  topDish20: { width: '20%', justifyContent: 'start', paddingLeft: '10px' },
  topDish25: { width: '25%', justifyContent: 'start', paddingLeft: '10px' },
  topDish30: { width: '30%', justifyContent: 'start', paddingLeft: '10px' },
  topDish40: { width: '40%', justifyContent: 'start', paddingLeft: '10px' },
  topDish0: { width: '45%', justifyContent: 'start', paddingLeft: '10px' },
  topDish1: { width: '50%', justifyContent: 'start', paddingLeft: '10px' },
  topDish2: { width: '55%', justifyContent: 'start', paddingLeft: '10px' },
  topDish3: { width: '60%', justifyContent: 'start', paddingLeft: '10px' },
  topDish4: { width: '65%', justifyContent: 'start', paddingLeft: '10px' },
  topDish5: { width: '70%', justifyContent: 'start', paddingLeft: '10px' },
  w06: { width: '6%' },
  w13: { width: '13%' },
  w17: { width: '17%' },
  w0: { width: '8%' },
  w1: { width: '10%' },
  w2: { width: '12%' },
  w3: { width: '15%' },
  w4: { width: '20%' },
  w5: { width: '22%' },
  w6: { width: '25%' },
  w7: { width: '30%' },
  w8: { width: '35%' },
  w9: { width: '40%' },
};

const itemsRight = [
  {
    key: 'North',
    label: 'North America',
    children: [
      {
        key: 'United States',
        label: 'United States',
      },
    ],
  },
  {
    key: 'Latin America',
    label: 'Latin America',
    children: [
      {
        key: 'Brazil',
        label: 'Brazil',
      },
      {
        key: 'Argentina',
        label: 'Argentina',
      },
      {
        key: 'Mexico',
        label: 'Mexico',
      },
    ],
  },
  {
    key: 'Europe',
    label: 'Europe',
    children: [
      {
        key: 'United Kingdom',
        label: 'United Kingdom',
      },
      {
        key: 'Belgium',
        label: 'Belgium',
      },
      {
        key: 'Germany',
        label: 'Germany',
      },
      {
        key: 'France',
        label: 'France',
      },
      {
        key: 'Netherlands',
        label: 'Netherlands',
      },
      {
        key: 'Italy',
        label: 'Italy',
      },
      {
        key: 'Poland',
        label: 'Poland',
      },
    ],
  },
  {
    key: 'Greater Asia',
    label: 'Greater Asia',
    children: [
      {
        key: 'Philippines',
        label: 'Philippines',
      },
      {
        key: 'Vietnam',
        label: 'Vietnam',
      },
      {
        key: 'Thailand',
        label: 'Thailand',
      },
    ],
  },
  {
    key: 'Indonesia',
    label: 'Indonesia',
    children: [
      {
        key: 'Indonesia',
        label: 'Indonesia',
      },
    ],
  },
  {
    key: '1 UL Africa, LATAM and Middle East',
    label: '1 UL Africa, LATAM and Middle East',
    children: [
      {
        key: 'South Africa',
        label: 'South Africa',
      },
      {
        key: 'Colombia',
        label: 'Colombia',
      },
      {
        key: 'Ethopia',
        label: 'Ethopia',
      },
      {
        key: 'Kenya',
        label: 'Kenya',
      },
      {
        key: 'Nigeria',
        label: 'Nigeria',
      },
      {
        key: 'Egypt',
        label: 'Egypt',
      },
      {
        key: 'Morocco',
        label: 'Morocco',
      },
    ],
  },
  {
    key: '1 UL Europe',
    label: '1 UL Europe',
    children: [
      {
        key: 'Finland',
        label: 'Finland',
      },
      {
        key: 'Spain',
        label: 'Spain',
      },
      {
        key: 'Sweden',
        label: 'Sweden',
      },
    ],
  },
  {
    key: '1UL APAC',
    label: '1UL APAC',
    children: [
      {
        key: 'Australia',
        label: 'Australia',
      },
    ],
  },
];

const smoothScrollTo = function(endX, endY, duration) {
  let startX = window.scrollX,
    startY = window.scrollY,
    distanceX = endX - startX,
    distanceY = endY - startY,
    startTime = new Date().getTime();

  let easeInOutQuart = function(time, from, distance, duration) {
    if ((time /= duration / 2) < 1)
      return (distance / 2) * time * time * time * time + from;
    return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
  };

  let timer = window.setInterval(function() {
    let time = new Date().getTime() - startTime,
      newX = easeInOutQuart(time, startX, distanceX, duration),
      newY = easeInOutQuart(time, startY, distanceY, duration);
    if (time >= duration) {
      window.clearInterval(timer);
    }
    window.scrollTo(newX, newY);
  }, 1000 / 120); // 120 fps
};

export {
  allTitle,
  allStyleCountry,
  styleData,
  itemsRight,
  smoothScrollTo,
  other,
};
