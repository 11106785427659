/* eslint-disable indent */
import { LoadingOutlined } from '@ant-design/icons';
import { Checkbox, Empty, Select } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getProduct } from '../../api/index.js';
import Box from '../box/index.jsx';
import ImageLazy from '../image-lazy/image-lazy.jsx';
import SubFilter from '../sub-filter/index.jsx';
import Chart from './chart.jsx';
import style from './compare.module.scss';
import Table from './table.jsx';
import Table2 from './table2.jsx';

const MAX_PRODUCTS = 4;

function BoxableContainer({
  className = '',
  children,
  active = false,
  onClick,
}) {
  return (
    <Box className={className} onClick={onClick} hasBox={active}>
      {children}
    </Box>
  );
}

function Compare({ step, setStep, search, filterData }) {
  // loading
  const [isLoading, setIsLoading] = useState(true);

  // mode
  const [mode, setMode] = useState(0);

  // sort
  // eslint-disable-next-line no-unused-vars
  const [sortKey, setSortKey] = useState('brand_logo_visibility');
  // eslint-disable-next-line no-unused-vars
  const [sort, setSort] = useState('desc');

  // list data
  const [listData, setListData] = useState([]);

  // get list data
  const getListData = useCallback(async () => {
    if (!filterData) return;
    setIsLoading(true);
    try {
      setListData([]);
      const { data } = await getProduct(
        filterData,
        undefined,
        search,
        sort,
        sortKey
      );
      setListData(
        data.map((e) => ({
          ...e,
          isLoadingImg: true,
        }))
      );
    } catch (err) {
      console.error('get list error', err);
    }
    setIsLoading(false);
  }, [setIsLoading, setListData, search, filterData, sort, sortKey]);
  useEffect(() => {
    void getListData();
  }, [getListData]);

  // product selected for compare
  const selectedProducts = useMemo(() => {
    return listData.filter((e) => e.isSelected);
  }, [listData]);

  // select product
  const onSelect = useCallback(
    (sn) => {
      if (selectedProducts.length >= MAX_PRODUCTS) return;
      setListData((prev) => {
        return prev.map((e) => {
          if (e.sn !== sn) return e;
          e.isSelected = !e.isSelected;
          return e;
        });
      });
    },
    [setListData, selectedProducts]
  );

  // check box button
  const [checkList, setCheckList] = useState([
    {
      title: 'Brand Logo Visibility',
      key: 'brand_logo_visibility',
      check: false,
    },
    {
      title: 'High Quality',
      key: 'premium',
      check: false,
    },
    {
      title: 'Variant Name Visibility',
      key: 'variant_name_visibility',
      check: false,
    },
    {
      title: 'Healthy',
      key: 'natural',
      check: false,
    },
    {
      title: 'Considered Appeal',
      key: 'appeal',
      check: false,
    },
    {
      title: 'Intuitive Appeal',
      key: 'intuitive_appeal',
      check: false,
    },
    {
      title: 'Premium',
      key: 'value',
      check: false,
    },
    {
      title: 'Unpriced Purchase Intent',
      key: 'upi',
      check: false,
    },
    {
      title: 'Sustainable',
      key: 'sustainable',
      check: false,
    },
    {
      title: 'Taste',
      key: 'taste',
      check: false,
    },
    {
      title: 'Modernity',
      key: 'modernity',
      check: false,
    },
    {
      title: 'Excitement',
      key: 'excitement',
      check: false,
    },
    {
      title: 'Desirability',
      key: 'desirability',
      check: false,
    },
  ]);
  const keys = useMemo(() => {
    return checkList.filter((k) => k.check);
  }, [checkList]);

  // handle check box
  const onCheckChange = useCallback(
    (index) => {
      setCheckList((prevCheckList) =>
        prevCheckList.map((item, i) =>
          i === index ? { ...item, check: !item.check } : item
        )
      );
    },
    [setCheckList]
  );

  // submit
  const canSubmit = useMemo(() => {
    const sp = selectedProducts.length;
    const c = checkList.some((e) => e.check);
    return sp > 0 && sp <= MAX_PRODUCTS && c;
  }, [selectedProducts, checkList]);

  const [subFilter, setSubFilter] = useState();
  const hasSubFilter = useMemo(() => {
    return Boolean(subFilter);
  }, [subFilter]);

  if (step === 0) {
    return (
      <div className={style.content}>
        <div className={style.centerWrap}>
          <div className={style.compareOuter}>
            <div className={style.compareScroll}>
              {isLoading ? (
                <div className={style.compareLoading}>
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                      color: '#00FF00',
                    }}
                    spin
                  />
                </div>
              ) : (
                <div className={style.compareList}>
                  {/* List */}
                  {listData.length === 0 ? (
                    <div className={classNames(style.placeholder, style.empty)}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </div>
                  ) : (
                    <div className={style.proList}>
                      {listData.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <BoxableContainer
                              className={classNames(style.imgUnit)}
                              onClick={() => {
                                onSelect(item.sn);
                              }}
                              active={item.isSelected}
                            >
                              <div className={classNames(style.imgRow)}>
                                <ImageLazy
                                  src={item.cover}
                                  showType={2}
                                  alt={item.display_name}
                                />
                                <div className={style.text}>
                                  {item.display_name}
                                </div>
                                <div className={style.imgRowBg}></div>
                              </div>
                            </BoxableContainer>
                            {(index + 1) % 3 === 0 &&
                              index !== listData.length - 1 && (
                                <div
                                  key={`divider-${index}`}
                                  className={style.divider}
                                ></div>
                              )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={style.descText}>
            {`Select pack designs to compare (now. ${selectedProducts.length}) (max. ${MAX_PRODUCTS})`}
          </div>
        </div>
        {/* Select */}
        <Box className={style.selectWrap}>
          <div className={style.selectInner}>
            <div className={style.checkBoxWrap}>
              <div className={style.checkBoxInner}>
                {checkList.map((item, index) => {
                  return (
                    <Checkbox
                      key={index}
                      checked={item.check}
                      className={style.checkItem}
                      onChange={() => {
                        onCheckChange(index);
                      }}
                    >
                      {item.title}
                    </Checkbox>
                  );
                })}
              </div>
            </div>
            <div className={style.checkDesc}>
              Select the metrics you want to compare
            </div>
            <div
              className={classNames(style.btnSubmit, {
                [style.grey]: !canSubmit,
              })}
              onClick={() => {
                if (!canSubmit) return;
                setStep(1);
              }}
            >
              SUBMIT
            </div>
          </div>
        </Box>
      </div>
    );
  } else if (step === 1) {
    return (
      <div className={style.optOuter}>
        <div className={style.optWrap}>
          {/* Subfilter */}
          <SubFilter data={subFilter} setData={setSubFilter} />
          <div className={style.content2}>
            {/*  mode */}
            <div className={style.modelSelectRow}>
              <Select
                value={mode}
                onChange={setMode}
                options={[
                  { label: 'Table', value: 0 },
                  { label: 'Chart', value: 1 },
                ]}
              />
            </div>
            {/* Compare */}
            {/* table */}
            {mode === 0 && (
              <div className={style.compareContent}>
                {hasSubFilter ? (
                  <Table2
                    data={selectedProducts}
                    keys={keys}
                    filter={filterData}
                    subFilter={subFilter}
                  />
                ) : (
                  <Table data={selectedProducts} keys={keys} />
                )}
              </div>
            )}
            {/* chart */}
            {mode === 1 && (
              <div className={style.compareContent}>
                <Chart
                  data={selectedProducts}
                  keys={keys}
                  subFilter={subFilter}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={style.btnBack}
          onClick={() => {
            setStep(0);
          }}
        >
          BACK
        </div>
      </div>
    );
  } else {
    <div>Unknown step {step}</div>;
  }
}

export default Compare;
