import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import { useMsal } from '@azure/msal-react';
import { App, Modal, Input, Select, Button } from 'antd';
import BorderContainer from '../../consumer-theater/components/border-container/border-container.jsx';
import style from './get-in-touch.module.scss';
import { postMessage } from '../api/request.js';

let message;

let ReasonForContactOptions = [
  {
    id: 0,
    value: 'New Idea to share',
  },
  {
    id: 1,
    value: 'A problem/bug encountered',
  },
  {
    id: 2,
    value: 'Other questions',
  },
];

let ModuleOptions = [
  {
    id: 0,
    value: 'Front line Time',
  },
  {
    id: 1,
    value: 'Consumer Theater',
  },
  {
    id: 2,
    value: 'Trend Dial',
  },
  {
    id: 3,
    value: 'Irresisitible Top Dish',
  },
  {
    id: 4,
    value: 'Concept AI',
  },
  {
    id: 5,
    value: 'ConceptGPT',
  },
  {
    id: 6,
    value: 'Plant Based',
  },
  {
    id: 7,
    value: 'Commericalizing Sustainability',
  },
  {
    id: 8,
    value: 'Foods Tree of Wisdom',
  },
];

// eslint-disable-next-line no-empty-pattern
let GetInTouch = ({}, ref) => {
  const { accounts } = useMsal();
  const account = accounts.length>0? accounts[0] : '';
  const [isModalOpen, setIsModalOpen] = useState(false);
  const staticFunction = App.useApp();
  message = staticFunction.message;

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const isValidEmail = (email) => { 
    return /\S+@\S+\.\S+/.test(email);
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsModalOpen(true);
    },
    close: () => {
      setIsModalOpen(false);
    },
  }));

  // emailAddress
  const [emailAddress, setEmailAddress] = useState(account.username);
  const setEmail = (val) => {
    setEmailAddress(val);
  };

  // new message
  const [newMessage, setNewMessageVal] = useState('');
  const setMessage = (val) => {
    setNewMessageVal(val);
  };

  // reason for contact
  const [reasonValue, setReasonValue] = useState('');
  const setReason = (val) => {
    setReasonValue(val);
  };

  // module
  const [module, setModuleValue] = useState('');
  const setModule = (val) => {
    setModuleValue(val);
  };

  const [disable, setDisable] = useState(true);

  useEffect(() => {
    const dis = !emailAddress || !newMessage || !reasonValue || !module 
    || !isValidEmail(emailAddress);
    setDisable(dis);
  }, [emailAddress, newMessage, reasonValue, module]);

  const [loading, setLoading] = useState(false);

  const submitHandle = () => {
    const data = {
      email: emailAddress,
      new_message: newMessage,
      reason: reasonValue,
      module: module
    };
    console.log(data);
    postMessage(data)
      .then((res) => {
        console.log(res);
        message.success('Message submitted successfully! 3s to reload :)');
        setTimeout(()=>{
          window.location.reload();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const afterCloseHandle = () => {
    setEmailAddress(account.username);
    setMessage('');
    setReasonValue(null);
    setModule(null);
    setLoading(false);
  };

  return (
    <Modal
      title=""
      wrapClassName={style.myDialog}
      width={930}
      centered
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
      afterClose={afterCloseHandle}
      closeIcon={<div className={style.closeBtn}></div>}
    >
      <div className={style.dialogBox}>
        <BorderContainer id="topic">
          <div className={style.dialogMain}>
            <div className={style.dialogTitle}>
              <div className={style.titleBox}>Get In Touch Form</div>
            </div>
            <div className={style.conditionBox}>
              <div className={style.formItem}>
                <div className={style.itemName}>Email address</div>
                <div className={style.itemInput}>
                  <Input
                    placeholder={emailAddress}
                    maxLength={80}
                    value={emailAddress}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className={style.formItem}>
                <div className={style.itemName}>Reason for Contact</div>
                <div className={style.itemInput}>
                  <Select
                    placeholder="Reason For Contact"
                    maxLength={500}
                    value={reasonValue}
                    onChange={setReason}
                    options={ReasonForContactOptions}
                  />
                </div>
              </div>
              <div className={style.formItem}>
                <div className={style.itemName}>Module</div>
                <div className={style.itemInput}>
                  <Select
                    placeholder="Specific Module"
                    maxLength={500}
                    value={module}
                    onChange={setModule}
                    options={ModuleOptions}
                  />
                </div>
              </div>
              <div className={style.formItem}>
                <div className={style.itemName}>Your Message</div>
                <div className={style.itemInput}>
                  <Input.TextArea
                    // autoSize={{minRow:2, maxRows:6}}
                    placeholder="Message here"
                    maxLength={500}
                    value={newMessage}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className={style.confirmBtn}>
              <Button
                type="primary"
                disabled={disable}
                block
                loading={loading}
                onClick={submitHandle}
              >
                CONFIRM
              </Button>
            </div>
          </div>
        </BorderContainer>
      </div>
    </Modal>
  );
};

GetInTouch = forwardRef(GetInTouch);

export default GetInTouch;

export { message };
