import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import style from './item.module.scss';

function Score({ className, title = 'Title', value = 0, rank = 0 }) {
  const colorClassName = useMemo(() => {
    if (rank <= 0.3) return style.dataGreen;
    else if (rank <= 0.7) return style.dataYellow;
    else return style.dataRed;
  }, [rank]);

  return (
    <div className={classNames(style.scoreContainer, className)}>
      <div className={style.scoreHeader}>
        <span className={style.scoreHeaderText}>{title}</span>
      </div>
      <div className={style.scoreBody}>
        <div className={classNames(style.scoreCircle, colorClassName)} />
        <div className={classNames(style.scoreValue, colorClassName)}>
          {value.toFixed(2)}
        </div>
      </div>
    </div>
  );
}

export function PackageAiItem({
  className,
  title = 'Title',
  appeal = 0,
  intuitiveAppeal = 0,
  hasParent = false,
  hasChildren = false,
  showChildren = false,
  setShowChildren = () => {},
  leftChildren = false,
  showScore = true,
}) {
  const appealRank = useMemo(() => {
    if (appeal > 70) return 0;
    else if (appeal > 40) return 0.5;
    else return 1;
  }, [appeal]);

  const intuitiveAppealRank = useMemo(() => {
    if (intuitiveAppeal > 70) return 0;
    else if (intuitiveAppeal > 40) return 0.5;
    else return 1;
  }, [intuitiveAppeal]);

  return (
    <div
      className={classNames(
        style.itemContainer,
        {
          [style.childLeft]: leftChildren,
        },
        className
      )}
    >
      <div className={style.itemHeader}>
        <div className={style.itemHeaderText}>{title}</div>
      </div>
      {showScore && (
        <div className={style.itemBody}>
          <Score title="Considered Appeal" value={appeal} rank={appealRank} />
          <Score
            title="Intuitive Appeal"
            value={intuitiveAppeal}
            rank={intuitiveAppealRank}
          />
        </div>
      )}
      {hasParent && <div className={style.parentLine} />}
      {hasChildren && showChildren ? (
        <>
          <div className={style.childLine} />
          <div
            className={style.childIcon2}
            onClick={() => setShowChildren(false)}
          >
            <MinusOutlined />
          </div>
        </>
      ) : (
        <div className={style.childIcon} onClick={() => setShowChildren(true)}>
          <PlusOutlined />
        </div>
      )}
    </div>
  );
}

export function PackageAiProduct({
  className,
  title = 'Title',
  image,
  appeal = 0,
  appealRank = 0,
  intuitiveAppeal = 0,
  intuitiveAppealRank = 0,
  indexPosition = 0,
}) {
  return (
    <div
      className={classNames(
        style.itemContainer,
        style.productContainer,
        className
      )}
    >
      <div className={style.itemHeader}>
        <div className={style.itemHeaderText}>{title}</div>
      </div>
      <div className={style.itemBody}>
        <div className={style.productImgBox}>
          <img loading="lazy" src={image} alt={title} />
        </div>
        <Score title="Considered Appeal" value={appeal} rank={appealRank} />
        <Score
          title="Intuitive Appeal"
          value={intuitiveAppeal}
          rank={intuitiveAppealRank}
        />
      </div>
      <div className={style.productParentLine} />
      {indexPosition > 0 && <div className={style.productVerticalLine} />}
    </div>
  );
}
