import React, { createContext, useState } from 'react';
import { useCategoryRank } from '../../hooks/category';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [avgs, setAvgs] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [originData, setOriginData] = useState([]);

  const { getCategoryRank } = useCategoryRank();

  const showModal = (obj, avgs, originData) => {
    setIsOpen(true);
    setModalData(obj);
    setAvgs(avgs);
    setOriginData(originData);
  };
  const closeModal = () => {
    setIsOpen(false);
    // setModalData({});
    // setAvgs([]);
    // setOriginData([]);
  };

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        modalData,
        showModal,
        closeModal,
        avgs,
        getCategoryRank,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
