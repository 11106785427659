import classNames from 'classnames';
import ReactECharts from 'echarts-for-react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getProduct } from '../../api/index.js';
import style from './chart.module.scss';

const COLORS = ['#026A1F', '#00A889', '#FFF45B', '#EB6878'];

const COLORS2 = [
  ['#0F2801', '#01603D', '#47B112', '#80FA05', '#C7FE7A', '#FDFFBC'],
  ['#013938', '#00918C', '#00DCC5', '#69FFEF', '#BCFFFC', '#F8FCFF'],
  ['#660003', '#964000', '#FA6800', '#FFAA45', '#FFE601', '#FFFBC1'],
  ['#4C0133', '#7B1A5B', '#E70072', '#FF8DBA', '#FFB8C3', '#FF48AB'],
];

const GENERATION_OPTIONS = [
  { label: 'Gen X', value: 'Gen X' },
  { label: 'Millennials', value: 'Millennials' },
  { label: 'Gen Z', value: 'Gen Z' },
  { label: 'Baby Boomers', value: 'Baby Boomers' },
];
const GENDER_OPTIONS = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
];
const USER_VS_NONE_USER_OPTIONS = [
  { label: 'Users', value: 'Users' },
  { label: 'Non-Users', value: 'Non-Users' },
];
const SOCIAL_CLASS_OPTIONS = [
  { label: 'Low Income', value: 'Low Income' },
  { label: 'Middle Income', value: 'Middle Income' },
  { label: 'High Income', value: 'High Income' },
];
const SUB_FILTERS = {
  generation: GENERATION_OPTIONS,
  gender: GENDER_OPTIONS,
  user_vs_nouser: USER_VS_NONE_USER_OPTIONS,
  social_class: SOCIAL_CLASS_OPTIONS,
};

function processName(name) {
  return name.split(' ')[0].slice(0, 10);
}

function processValue(val) {
  const [i, d] = val.toFixed(1).split('.');
  if (d === '0') {
    return `${i}%`;
  } else {
    return `${i}.${d}%`;
  }
}

function Chart({ className, title, data = [], kkey }) {
  const chartRef = useRef(null);

  const option = useMemo(() => {
    chartRef.current?.getEchartsInstance().clear();

    return {
      textStyle: {
        fontSize: 9,
        color: '#fff',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        textStyle: { fontSize: 9 },
        valueFormatter: (value) => {
          return `${value.toFixed(2)}%`;
        },
      },
      grid: {
        top: '9%',
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          axisLabel: { fontSize: 9 },
          data: data.map((e) => processName(e.display_name)),
        },
      ],
      yAxis: [{ type: 'value' }],
      series: [
        {
          name: title,
          type: 'bar',
          label: {
            show: true,
            position: 'inside',
            formatter: (v) => {
              return processValue(v.value);
            },
          },
          data: data.map((e, index) => {
            return {
              value: e[kkey],
              itemStyle: {
                color: COLORS[index % COLORS.length],
              },
            };
          }),
        },
      ],
    };
  }, [data, kkey]);

  return (
    <div className={classNames(style.chartBox, className)}>
      <div className={style.chartTitle}>
        <div className={style.icon} />
        <span className={style.text}> {title}</span>
      </div>
      <ReactECharts
        ref={chartRef}
        className={style.chart}
        isAnimationActive={true}
        option={option}
      />
    </div>
  );
}

function Chart2({ className, title, data = [], subData, kkey }) {
  const chartRef = useRef(null);

  const option = useMemo(() => {
    chartRef.current?.getEchartsInstance().clear();

    const dd = [];
    let i = 0;
    for (const [key, value] of subData) {
      const d = {
        name: key,
        type: 'bar',
        data: [],
      };

      let j = 0;
      for (const dd of data) {
        const colors = COLORS2[j % COLORS.length];
        const color = colors[i % colors.length];
        const p = value.get(dd.sn);
        if (p) {
          d.data.push({
            value: p[kkey],
            label: {
              show: true,
              position: 'inside',
              formatter: (v) => {
                return processValue(v.value);
              },
            },
            itemStyle: {
              color: color,
            },
          });
        } else {
          d.data.push({ value: 0 });
        }
        j += 1;
      }

      dd.push(d);
      i += 1;
    }

    const labels = data.map((e) => processName(e.display_name));

    return {
      textStyle: {
        fontSize: 9,
        color: '#fff',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'shadow' },
        textStyle: { fontSize: 9 },
      },
      grid: {
        top: '9%',
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          axisLabel: { fontSize: 9 },
          data: labels,
        },
      ],
      yAxis: [{ type: 'value' }],
      series: dd,
    };
  }, [data, subData, kkey]);

  return (
    <div className={classNames(style.chartBox, className)}>
      <div className={style.chartTitle}>
        <div className={style.icon} />
        <span className={style.text}> {title}</span>
      </div>
      <ReactECharts
        ref={chartRef}
        className={style.chart}
        isAnimationActive={true}
        option={option}
      />
    </div>
  );
}

function ChartContainer({ className, children }) {
  return (
    <div className={classNames(style.box1, className)}>
      <div className={style.box11}>{children}</div>
    </div>
  );
}

function ChartView({ className, data = [], keys = [], subFilter }) {
  const ids = useMemo(() => {
    return data.map((e) => e.sn);
  }, [data]);
  const options = useMemo(() => {
    if (!subFilter) return [];
    return SUB_FILTERS[subFilter] ?? [];
  }, [subFilter]);
  const [subData, setSubData] = useState(new Map());

  const getData2 = useCallback(async () => {
    if (!ids.length) return;
    if (!options.length) return;
    try {
      const l = new Map();
      for (const o of options) {
        const { data: pd } = await getProduct(
          undefined,
          { [subFilter]: [o.value] },
          undefined,
          undefined,
          undefined,
          ids
        );
        const ppd = new Map();
        pd.forEach((p) => {
          ppd.set(p.sn, p);
        });
        l.set(o.value, ppd);
      }
      setSubData(l);
    } catch (err) {
      console.error('get data 2 error', err);
    }
  }, [ids, options]);
  useEffect(() => {
    void getData2();
  }, [subFilter]);

  if (!options.length) {
    return (
      <ChartContainer className={className}>
        {keys.map((key) => {
          return (
            <Chart
              key={`${key.key}1`}
              title={key.title}
              data={data}
              kkey={key.key}
            />
          );
        })}
      </ChartContainer>
    );
  } else {
    return (
      <ChartContainer className={className}>
        {keys.map((key) => {
          if (
            key.key === 'brand_logo_visibility' ||
            key.key === 'variant_name_visibility'
          ) {
            return (
              <Chart
                key={`${key.key}1`}
                title={key.title}
                data={data}
                kkey={key.key}
              />
            );
          } else {
            return (
              <Chart2
                key={`${key.key}2`}
                title={key.title}
                data={data}
                subData={subData}
                kkey={key.key}
                ids={ids}
                options={options}
                subKey={subFilter}
              />
            );
          }
        })}
      </ChartContainer>
    );
  }
}

export default ChartView;
