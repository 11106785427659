import { ConfigProvider, theme } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import Box from '../../components/box/index.jsx';
import TopBar from '../../components/top-bar/top.jsx';
import style from './help.module.scss';

const TrendsDialPage = () => {
  const tabList = [
    { title: 'Module Manuals' },
    { title: 'Brand Information' },
    { title: 'Other Packaging Best Practices' },
  ];

  const moduleUrl = [{ title: 'How to use the module', url: '####' }];
  const brandUrl = [
    { title: 'Brand Visual Identity (BVI)', url: '####' },
    { title: 'Distinctive Brand Assets', url: '####' },
    { title: 'Brand Packaging Guidelines', url: '####' },
  ];
  const otherUrl = [
    { title: 'Colour Themes Best Practices', url: '####' },
    { title: 'Triggers & Barriers', url: '####' },
    { title: 'Needstates & Occasions', url: '####' },
  ];

  const [activeTab, setActiveTab] = useState(0);
  const toggleTab = (index) => {
    setActiveTab(index);
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className={style.fixedContent}>
        <TopBar />
        <Box className={style.contentWrap}>
          <div className={style.contentInner}>
            <div className={style.tabBar}>
              <div className={classNames(style.firstTab, style.active)}>
                <div className={style.outerCircle}>
                  <div className={style.innerCircle}></div>
                </div>
                <span className={style.navTitle}>
                  {activeTab === 0 ? 'Help & Resources' : 'Innovation Library'}
                </span>
              </div>
            </div>
            <div className={style.helpContent}>
              <div className={style.nav}>
                <ul className={style.list}>
                  {tabList.map((tab, index) => (
                    <li
                      key={index}
                      onClick={() => toggleTab(index)}
                      className={classNames({
                        [style.active]: activeTab === index,
                      })}
                    >
                      {tab.title}
                    </li>
                  ))}
                </ul>
                <div className={style.border}></div>
              </div>
              <div className={style.url}>
                {activeTab === 0 && (
                  <div>
                    {moduleUrl.map((item, index) => (
                      <div key={index} className={style.wrap}>
                        <a
                          className={style.link}
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                        ></a>
                        <span className={style.text}>{item.title}</span>
                        <s className={style.arr}></s>
                      </div>
                    ))}
                  </div>
                )}
                {activeTab === 1 && (
                  <div>
                    {brandUrl.map((item, index) => (
                      <div key={index} className={style.wrap}>
                        <a
                          className={style.link}
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                        ></a>
                        <span className={style.text}>{item.title}</span>
                        <s className={style.arr}></s>
                      </div>
                    ))}
                  </div>
                )}
                {activeTab === 2 && (
                  <div>
                    {otherUrl.map((item, index) => (
                      <div key={index} className={style.wrap}>
                        <a
                          className={style.link}
                          href={item.url}
                          target="_blank"
                          rel="noreferrer"
                        ></a>
                        <span className={style.text}>{item.title}</span>
                        <s className={style.arr}></s>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </ConfigProvider>
  );
};

export default TrendsDialPage;
