import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getProduct } from '../../api/index.js';
import Chart from '../scatter-chart/index.jsx';
import Table from '../table/table.jsx';
import style from './perceived.module.scss';

const HEADER = [
  {
    title: 'Taste',
    key: 'taste',
    tips: 'This metric measures the percentage of respondents who somewhat or strongly believe the product would have a delicious taste based on its design alone. It is derived from consumer survey data.',
    value: 0,
    isSortDesc: true,
    isSortAsc: false,
  },
  {
    title: 'High Quality',
    key: 'premium',
    tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product is of high quality based on its design alone. It is derived from consumer survey data.',
    value: 0,
    isSortDesc: false,
    isSortAsc: false,
  },
  {
    title: 'Healthy',
    key: 'natural',
    tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product is a healthy choice based on its design alone. It is derived from consumer survey data.',
    value: 0,
    isSortDesc: false,
    isSortAsc: false,
  },
  {
    title: 'Premium',
    key: 'value',
    tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product would be worth paying more for based on its design alone. It is derived from consumer survey data.',
    value: 0,
    isSortDesc: false,
    isSortAsc: false,
  },
  {
    title: 'Sustainable',
    key: 'sustainable',
    tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product is a more environmentally friendly choice based on its design alone. It is derived from consumer survey data.',
    value: 0,
    isSortDesc: false,
    isSortAsc: false,
  },
  {
    title: 'Modernity',
    key: 'modernity',
    tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product looks modern based on its design alone. It is derived from consumer survey data.',
    value: 0,
    isSortDesc: false,
    isSortAsc: false,
  },
  {
    title: 'Excitement',
    key: 'excitement',
    tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product looks exciting based on its design alone. It is derived from consumer survey data.',
    value: 0,
    isSortDesc: false,
    isSortAsc: false,
  },
  {
    title: 'Desirability',
    key: 'desirability',
    tips: 'This metric measures the percentage of respondents who somewhat or strongly think that the product looks like something that they would want to have. It is derived from consumer survey data.',
    value: 0,
    isSortDesc: false,
    isSortAsc: false,
  },
];

function Perceived({ className, tab = 'table', filterData, subFilterData }) {
  // loading
  const [isLoading, setIsLoading] = useState(true);

  // sort
  const [sortKey, setSortKey] = useState('taste');
  const [sort, setSort] = useState('desc');
  const changSort = (key) => {
    if (sortKey === key) {
      setSort(sort === 'desc' ? 'asc' : 'desc');
    } else {
      setSort('desc');
      setSortKey(key);
    }
  };

  // data
  const [originData, setOriginData] = useState([]);

  // avg
  const avgData = useMemo(() => {
    if (originData.length === 0) return {};

    let taste = 0;
    let premium = 0;
    let natural = 0;
    let value = 0;
    let sustainable = 0;
    let modernity = 0;
    let excitement = 0;
    let desirability = 0;
    for (const item of originData) {
      taste += item.taste;
      premium += item.premium;
      natural += item.natural;
      value += item.value;
      sustainable += item.sustainable;
      modernity += item.modernity;
      excitement += item.excitement;
      desirability += item.desirability;
    }
    taste /= originData.length;
    premium /= originData.length;
    natural /= originData.length;
    value /= originData.length;
    sustainable /= originData.length;
    modernity /= originData.length;
    excitement /= originData.length;
    desirability /= originData.length;

    return {
      taste: taste,
      premium: premium,
      natural: natural,
      value: value,
      sustainable: sustainable,
      modernity: modernity,
      excitement: excitement,
      desirability: desirability,
    };
  }, [originData]);

  // headerData
  const headerData = useMemo(() => {
    return HEADER.map((item) => {
      return {
        ...item,
        value: avgData[item.key] ?? 0.0,
        isSortDesc: item.key === sortKey && sort === 'desc',
        isSortAsc: item.key === sortKey && sort === 'asc',
      };
    });
  }, [avgData, sortKey, sort]);

  // fetch data
  const fetchData = useCallback(
    async (sort, order) => {
      setIsLoading(true);
      setOriginData([]);
      const { data } = await getProduct(
        filterData,
        subFilterData,
        undefined,
        sort,
        order
      );
      setOriginData(data);
      setIsLoading(false);
    },
    [filterData, subFilterData]
  );
  useEffect(() => {
    void fetchData();
  }, [filterData, subFilterData]);

  // sort
  const sortedData = useMemo(() => {
    return originData.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return sort === 'asc' ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return sort === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [originData, sortKey, sort]);

  // body data
  const bodyData = useMemo(() => {
    return sortedData.map((item) => {
      return {
        id: item.sn,
        name: item.display_name,
        market: item.market,
        cover: item.cover,
        value: [
          item.taste,
          item.premium,
          item.natural,
          item.value,
          item.sustainable,
          item.modernity,
          item.excitement,
          item.desirability,
        ],
      };
    });
  });

  if (tab === 'chart') {
    return (
      <div className={classNames(style.chartBox, className)}>
        <Chart className={style.chart} data={originData} />
      </div>
    );
  } else if (tab === 'table') {
    return (
      <div className={classNames(style.tblWrap, className)}>
        <Table
          className={style.table}
          header={headerData}
          data={bodyData}
          onAvgClick={changSort}
          loading={isLoading}
          originData={originData}
          small={true}
          isPercent={true}
        />
      </div>
    );
  } else {
    return <div>Unknown tab {tab}</div>;
  }
}

export default Perceived;
